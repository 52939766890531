import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStore } from 'root/store/context/store-context';
import './basic-info.less'

import { getBasicInfo } from 'root/helpers/api';
//TODO: translations, check hl7 spec for correct structure definition, destructuring assignments
import image from 'images/kanta_logo.png';
import icon from 'images/icons/user-info-icon.svg'



const BasicInfo = observer(() => {
    const { t } = useTranslation()
    const { patientStore } = useStore()
    const { id: patientId } = patientStore.getPatientData()
    const [patient, setPatient] = useState({
        resourceType: "",
        id: "",
        gender: "",
        birthDate: "",
        height: 0,
        weight: 0,
        smoking: "",
    })

    useEffect(() => {
        const fetchData = async () => {
            const id = patientId.toString()
            if (id) {
                const data = await getBasicInfo(id)
                return data
            }
            return null
        }
        
        if (patientId) {
            const result = fetchData().then((data) => {
                const patientHeight = data.extension.find(ext => ext.url === "http://hl7.org/fhir/StructureDefinition/patient-height")?.valueQuantity?.value || 0
                const patientWeight = data.extension.find(ext => ext.url === "http://hl7.org/fhir/StructureDefinition/patient-weight")?.valueQuantity?.value || 0
                const doesPatientSmoke = data.extension.find(ext => ext.url === "http://hl7.org/fhir/StructureDefinition/patient-smokingstatus")?.valueCodeableConcept?.coding[0].display || ""
    
                setPatient({
                    resourceType: data.resourceType,
                    id: data.id,
                    gender: data.gender,
                    birthDate: data.birthDate,
                    height: Number(patientHeight),
                    weight: Number(patientWeight) ,
                    smoking: String(doesPatientSmoke)
                })
    
            })
        }
    }, [patientId])

    const calculateAge = (birthDate: string) => {
        const dob = new Date(birthDate)
        if (isNaN(dob.getTime())) {
            return 0 
        }
        const ageDiffMs = Date.now() - dob.getTime()
        const ageDate = new Date(ageDiffMs)
        return Math.abs(ageDate.getUTCFullYear() - 1970)
    }

    const calculateBMI = (height: number, weight: number) => {
        if (height === 0) {
            return 0
        }
        const bmi = weight / ((height / 100) ** 2)

        return bmi.toFixed(1)
    }

    const bmi = calculateBMI(Number(patient.height), Number(patient.weight))

    const getBmiColor = (bmi: number) => {
        if (bmi > 30)
            return 'red'
        if (bmi > 25 && bmi <= 30)
            return 'orange'
    }

    const age = calculateAge(patient.birthDate)

    return (
        <div className="hippo-module basic-info-container">
            <h2 className="header2">{t('Basic information')}</h2>
            <img className="module-icon" src={icon}/>
            <div className="basic-info-list">
                <div className="module-text-row">
                    <div className="text-header">{t('Age')}</div>
                    <div className="text-value">{age}</div>
                </div>
                <div className="module-text-row">
                    <div className="text-header">{t('Gender')}</div>
                    <div className="text-value">{patient.gender}</div>
                </div>
                <div className="module-text-row">
                    <div className="text-header">{t('Height')}</div>
                    <div className="text-value">{patient.height}</div>
                </div>
                <div className="module-text-row">
                    <div className="text-header">{t('Weight')}</div>
                    <div className="text-value">{patient.weight}</div>
                </div>
                <div className="module-text-row">
                    <div className="text-header">{t('Body mass index')}</div>
                    <div className="text-value" style={{ color: getBmiColor(Number(bmi)) }}>{bmi}</div>
                </div>
                <div className="module-text-row">
                    <div className="text-header">{t('Smokes')}</div>
                    <div className="text-value">{patient.smoking?.toString()}</div>
                </div>
            </div>
            <div className="hippo-source">
                <span className="source-txt"></span>
                <img className="source-logo" src={image} />
            </div>

        </div>
    )
})

export default BasicInfo