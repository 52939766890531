import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStore } from 'root/store/context/store-context';

import { getPrescriptions } from 'root/helpers/api';

import './prescriptions.less'

import image from 'images/kanta_logo.png';
import icon from 'images/icons/medicine-icon.svg'

interface Display {
    display: string
}

interface DosageInstruction {
    textTranslation: Display[]
}

interface MedicationCodeableConcept {
    text: string
}

interface Resource {
    medicationCodeableConcept: MedicationCodeableConcept
    authoredOn: string
    dosageInstruction: DosageInstruction[]
}

interface Entry {
    resource: Resource
}

interface Prescriptions {
    resourceType: string
    type: string
    entry: Entry[]
}

const Prescriptions = observer(() => {
    const { t } = useTranslation()
    const { patientStore } = useStore()
    const { id: patientId } = patientStore.getPatientData()

    const [prescriptions, setPrescriptions] = useState<Prescriptions>()

    useEffect(() => {
        const fetchData = async () => {
            const id = patientId.toString()
            if (id) {
                const data = await getPrescriptions(id)
                return data
            }
            return null
        }

        if (patientId) {
            const result = fetchData().then((data) => setPrescriptions(data))
        }
    }, [patientId])

    const hasAllergy = (medication : string) => {
        if (medication == 'Aspirin')
            return '#FCAE1E'
        return ''
    }

    // TODO: added source, need to make it more general on a higher level component eventually
    return (
        <div className="hippo-module">
            <h2>{t('Medication list')}</h2>            
            <img className="module-icon" src={icon} />
            <div className="prescriptions-container">
                {prescriptions?.entry.map((prescription, i) =>
                    <div className="prescription-entry hippo-list-item" key={i} style={{color: hasAllergy(prescription.resource.medicationCodeableConcept.text)}}>
                        <div style={{display: "flex"}}>
                            <div className="prescription-name">{prescription.resource.medicationCodeableConcept.text}</div>
                            <div className="prescription-date">{prescription.resource.authoredOn}</div>
                        </div>
                        <div className="prescription-dosage">{prescription.resource.dosageInstruction[0].textTranslation[0].display}</div>                        
                    </div>
                )}
            </div>
            <div className="hippo-source">
                <span className="source-txt"></span>
                <img className="source-logo" src={image} />
            </div>
        </div>
    )
})

export default Prescriptions