import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import {
  	DndContext,
  	DragOverlay,
  	PointerSensor,
  	useSensor,
  	useSensors,
  	DragStartEvent,
  	DragOverEvent,
} from '@dnd-kit/core'
import { SortableContext, useSortable, arrayMove } from '@dnd-kit/sortable'
import { useStore } from 'root/store/context/store-context'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import './dnd-grid.less'

interface Module {
	Component: React.ComponentType<any>
	expandedSize?: { row: number, col: number }
}
  

interface GridProps {
	id: string
  	modules: Module
  	isEditing: boolean
  	gridGap: number
  	moduleHeight: number
	saveGridLayout: number
	cancelGridChanges: number
  	onGridLayoutChange: React.Dispatch<React.SetStateAction<boolean>>
}

interface GridItem {
  	id: string
  	Component: React.ComponentType<any>
  	currentSize: { row: number; col: number }
  	defaultSize: { row: number; col: number }
  	expandedSize?: { row: number; col: number }
}

interface DraggableModuleProps {
  	module: GridItem
  	draggable: boolean
  	resizable: boolean
  	resizeModule: (id: string, size: 'default' | 'expand', isPreview?: boolean) => void
  	createNewModule: (id: string, Component: React.ComponentType<any>) => void
  	removeModule: (id: string) => void
  	gridGap: number
  	moduleHeight: number
}

export interface GridComponentHandle {
	resetGrid: () => void,
	saveGridChanges: () => void
}

const DraggableModule = React.memo(({ module, draggable, resizable, resizeModule, createNewModule, removeModule, gridGap, moduleHeight }: DraggableModuleProps) => {
 	const { t } = useTranslation()
	const { id } = module

  	const { setNodeRef, attributes, listeners, isDragging, transform, transition } = useSortable({
		id,
		disabled: !draggable,
 	})

  	const height = moduleHeight * module.currentSize.row + (module.currentSize.row - 1) * gridGap

  	const isExpanded = _.isEqual(module.currentSize, module.defaultSize) && module.expandedSize

  	const handleExtend = () => {
		resizeModule(id, isExpanded ? 'expand' : 'default', true)
  	}

  	return (
		<div
	  		ref={setNodeRef}
	  		data-id={id}
	  		className={`module-container ${draggable ? 'editing' : ''} ${isDragging ? 'dragging' : ''}`}
	  		style={{
				gridRow: `span ${module.currentSize.row}`,
				gridColumn: `span ${module.currentSize.col}`,
				height: `${height}px`,
				opacity: isDragging ? 0.5 : 1,
				transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
				transition,
	  		}}
	  		{...attributes}
	  		{...listeners}
		>
	  	<module.Component
			id={id}
			resizeModule={(size) => resizeModule(id, size)}
			createNewModule={createNewModule}
			removeModule={removeModule}
	  	/>
	  	{draggable && resizable && (
			<button className="expand-button" onClick={handleExtend}>
			  	{isExpanded ? `${t('Expand module')}` : `${t('Collapse module')}`}
			</button>
	  	)}
	</div>
  )
})

const DndGrid: React.FC<GridProps> = forwardRef(({
	id,
  	modules,
  	isEditing,
  	gridGap,
  	moduleHeight,
  	onGridLayoutChange
}, ref) => {
	const { patientStore } = useStore()

  	const [snapshotNode, setSnapshotNode] = useState<HTMLElement | null>(null)
	const [expandedModuleIds, setExpandedModuleIds] = useState<string[]>([])

  	const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 10 } }))

	const { id: patientId } = patientStore.getPatientData()

	const loadModules = (modules, gridId) => {

        const savedModulesJson = localStorage.getItem(`${gridId}_${patientId}`)
        if (!savedModulesJson) {
            return modules
        }
    
        const savedModules = JSON.parse(savedModulesJson)
        const loadedModules = {}
    	savedModules.forEach(id => {
    	    if (modules[id]) {
    	        loadedModules[id] = {
    	            Component: modules[id].Component,
    	            ...(modules[id].expandedSize ? { expandedSize: modules[id].expandedSize } : {})
    	        }
    	    }
    	})
		return loadedModules
    }

	const constructGridItems = (modules: Record<string, Module>) => {
		const items = Object.entries(modules).map(([id, { Component, expandedSize }]) => ({
		  id,
		  Component,
		  currentSize: { row: 1, col: 1 },
		  defaultSize: { row: 1, col: 1 },
		  ...(expandedSize ? { expandedSize } : {})
		}))
		return items
  	}

	const [gridItems, setGridItems] = useState(() => constructGridItems(loadModules(modules, id)))
	const [initialGridItems, setInitialGridItems] = useState(gridItems)

	const resizeModule = (id: string, size: 'default' | 'expand', isPreview = false) => {
		const handlePreviewResizing = () => {
			if (size === 'expand') {
				setExpandedModuleIds(prevIds => [...new Set([...prevIds, id])])
			}
			else {
				setExpandedModuleIds(prevIds => prevIds.filter(moduleId => moduleId !== id))
			}
		}

		setGridItems(modules =>
			modules.map(module => {
			  if (module.id === id) {
				if (isEditing && !isPreview) {
				  handlePreviewResizing()
				}
		  
				return {
				  ...module,
				  currentSize: size === 'expand' ? module.expandedSize || { row: 1, col: 1 } : module.defaultSize
				}
			  }
			  return module
			})
		)
	}

	const collapseAllModules = () => {
		setGridItems(modules => modules.map(module => ({
			...module,
			currentSize: module.defaultSize
		})))
	}
	
	const resetModuleSizes = () => {
		setGridItems(modules =>
			modules.map(module => {
			  if (expandedModuleIds.includes(module.id)) {
				return {
				  ...module,
				  currentSize: module.expandedSize || { row: 1, col: 1 }
				}
			  }
			  return {
				...module,
				currentSize: module.defaultSize
			  }
			})
		)
		setExpandedModuleIds([])
	}

	const removeModule = (id) => {
		setGridItems((modules) => modules.filter(module => module.id !== id))
	}

	const createNewModule = (id, Component, parentId = null) => {
		const newModule = { id, Component, currentSize: {row: 1, col: 1}, defaultSize: {row: 1, col: 1} }
	
		const updateModules = (modules) => {
			if (modules.some(module => module.id === id)) {
				return modules 
			}
	
			if (parentId) {
				const parentIndex = modules.findIndex(module => module.id === parentId)
				return [
					...modules.slice(0, parentIndex + 1),
					newModule,
					...modules.slice(parentIndex + 1)
				]
			}
			return [...modules, newModule]
		}

		setGridItems((modules) => updateModules(modules))
	}

  	const handleDragStart = (event: DragStartEvent) => {
		collapseAllModules()

		const module = gridItems.find((m) => m.id === event.active.id)

		if (module) {
		  	const node = document.querySelector(`[data-id="${module.id}"]`) as HTMLElement
		  	if (node) {
				setSnapshotNode(node.cloneNode(true) as HTMLElement)
		  	}
		}
  	}

  	const handleDragOver = (event: DragOverEvent) => {
		const { active, over } = event
		if (!over) return

		const oldIndex = gridItems.findIndex((module) => module.id === active.id)
		const newIndex = gridItems.findIndex((module) => module.id === over.id)

		if (oldIndex !== -1 && newIndex !== -1 && oldIndex !== newIndex) {
		  	const newGrid = arrayMove(gridItems, oldIndex, newIndex)
		  	setGridItems(newGrid)
		}
  	}

  	const handleDragEnd = () => {
		setSnapshotNode(null)

		const hasGridChanged = JSON.stringify(gridItems) !== JSON.stringify(initialGridItems)
		onGridLayoutChange(hasGridChanged)
  	}

	const resetGrid = () => {
		setGridItems([...initialGridItems])
	}

	const saveGridChanges = () => {
		const storeToLocalStorage = () => {
			const moduleData = gridItems.map(({ id }) => id)
        	localStorage.setItem(`${id}_${patientId}`, JSON.stringify(moduleData))
		}
		
		storeToLocalStorage()
		setInitialGridItems(gridItems)
	}

	const storeExpandedModuleIds = () => {
		const sizesAreEqual = (size1, size2) => {
			return size1.row === size2.row && size1.col === size2.col
		}

		const idsOfExpandedModules = gridItems
			.filter(module => !sizesAreEqual(module.currentSize, module.defaultSize))
			.map(module => module.id)

		setExpandedModuleIds(prevIds => [...new Set([...prevIds, ...idsOfExpandedModules])])
	}

	useEffect(() => {
		if (isEditing) {
			collapseAllModules()

			storeExpandedModuleIds()
		}
		else {
			resetModuleSizes()
		}
	}, [isEditing])

	useImperativeHandle(ref, () => ({
        resetGrid,
		saveGridChanges
    }))

  	return (
		<DndContext sensors={sensors} onDragStart={handleDragStart} onDragOver={handleDragOver} onDragEnd={handleDragEnd}>
		  <SortableContext items={gridItems.map((module) => module.id)}>
			<div className="dnd-container" style={{ gap: `${gridGap}px` }}>
			  {gridItems.map((item) => (
				<DraggableModule
				  key={item.id}
				  module={item}
				  draggable={isEditing}
				  resizable={!!item.expandedSize}
				  resizeModule={resizeModule}
				  createNewModule={createNewModule}
				  removeModule={removeModule}
				  gridGap={gridGap}
				  moduleHeight={moduleHeight}
				/>
			  ))}
			</div>
		  </SortableContext>
		  <DragOverlay>
			{snapshotNode ? (
			  <div className="drag-overlay" dangerouslySetInnerHTML={{ __html: snapshotNode.outerHTML }} />
			) : null}
		  </DragOverlay>
		</DndContext>
  	)
})

export default DndGrid