import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import './dashboard.less'
import DashboardGrid, { GridComponentHandle } from 'root/components/dnd-grid/dnd-grid'
import { DropDownMenu, MenuItem } from 'root/components/drop-down-menu/drop-down-menu'

import BasicInfo from 'root/hippo-modules/basic-info/basic-info'
import Allergies from 'root/hippo-modules/allergies/allergies'
import ChronicDiseases from 'root/hippo-modules/chronic-diseases/chronic-diseases'
import Prescriptions from 'root/hippo-modules/prescriptions/prescriptions'
import Summary from 'root/hippo-modules/summary/summary'
import BloodPressureChart from 'root/hippo-modules/blood-pressure-chart/blood-pressure-chart'
import Oura from 'root/hippo-modules/oura/oura-resting-hr'
import WalkingDistanceChart from 'root/hippo-modules/walking-distance/walking-distance'
import LabResults from '../../hippo-modules/lab-results/lab-results'
import { useStore } from 'root/store/context/store-context'
import { searchPatient } from 'root/helpers/api'

const moduleHeight = 450
const gridGap = 30

const Dashboard = observer(() => {
    // global state with current selected patient needed
    const { t } = useTranslation()
    const { patientStore } = useStore()
    const { id: patientId, name, ssn } = patientStore.getPatientData()
    const { id } = useParams()

    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [hasGridChanged, setHasGridChanged] = useState(false)
    const kantaGridRef = useRef<GridComponentHandle | null>(null)
    const otherGridRef = useRef<GridComponentHandle | null>(null)

    const kantaId = 'kanta'
    const otherId = 'other'

    const kantaModules = {
        Summary: { Component: Summary },
        LabResults: { Component: LabResults, expandedSize: {row: 2, col: 1} },
        BasicInfo: { Component: BasicInfo },
        ChronicDiseases: { Component: ChronicDiseases },
        Allergies: { Component: Allergies },
        Prescriptions: { Component: Prescriptions },
        BloodPressureChart: { Component: BloodPressureChart }
    }

    const otherModules = {
        Oura: { Component: Oura },
        WalkingDistanceChart: { Component: WalkingDistanceChart }
    }

    const handleEditGridClick = () => {
        if (isEditing) {
            btCancelGridChanges()
            return
        }

        setIsEditing(true)
    }

    const btSaveGridChanges = () => {
        if (kantaGridRef.current && otherGridRef.current) {
            kantaGridRef.current.saveGridChanges()
            otherGridRef.current.saveGridChanges()
            
            setIsEditing(false)
        }
    }

    const btCancelGridChanges = () => {
        if (kantaGridRef.current && otherGridRef.current) {
            kantaGridRef.current.resetGrid()
            otherGridRef.current.resetGrid()
            
            setIsEditing(false)
        }
    }
    
    const fetchAndSetPatient = async (patientId: string) => {
        try {
            const patients = await searchPatient('')
            const selectedPatient = patients.find(patient => patient.id === patientId)
    
            if (selectedPatient) {
                patientStore.setPatientData(
                    selectedPatient.id,
                    selectedPatient.name,
                    selectedPatient.ssn
                )   
          }
        } catch (error) {
            console.error('Failed to fetch and set patient:', error)
        }
      }

    // TODO: safeguards for invalid browser urls/ids 
    useEffect(() => {
        // Fetching new patient data if url parameter doesn't match current patient id
        if (id !== patientId && id) {
            fetchAndSetPatient(id)
        }
    }, [id])
    
    return (
        <div className="dashboard-container">
            { id === patientId && 
            <>
                <div className="dashboard-taskbar">
                    <div className="patient-identification">{name} - {ssn}</div>
                    <div className="right-buttons">
                        {isEditing && (
                            <>
                                <div 
                                    className={`save-button ${hasGridChanged ? "" : "disabled" }`} 
                                    onClick={hasGridChanged ? btSaveGridChanges : undefined}
                                >
                                    {t('Save')}
                                </div>
                                <div className="cancel-button" onClick={btCancelGridChanges}>
                                    {t('Cancel')}
                                </div>
                            </>
                        )}
                        <DropDownMenu>
                            <MenuItem onClick={handleEditGridClick}>{t('Edit dashboard layout')}</MenuItem>
                        </DropDownMenu>
                    </div>
                </div>

                <DashboardGrid
                    id={kantaId}
                    modules={kantaModules}
                    isEditing={isEditing}
                    gridGap={gridGap}
                    moduleHeight={moduleHeight}
                    onGridLayoutChange={setHasGridChanged}
                    ref={kantaGridRef}
                />

                <h2 className="dashboard-header">Patient self-measurements</h2>

                <DashboardGrid
                    id={otherId}
                    modules={otherModules}
                    isEditing={isEditing}
                    gridGap={gridGap}
                    moduleHeight={moduleHeight}
                    onGridLayoutChange={setHasGridChanged}
                    ref={otherGridRef}
                />
            </>}
        </div>
    )
})

export default Dashboard