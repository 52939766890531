import React, { useState, useEffect, useRef } from 'react'
import icon from 'images/icons/settings-icon.svg'

import './drop-down-menu.less'

interface DropDownMenuProps {
    children: React.ReactElement<MenuItemProps>[] | React.ReactElement<MenuItemProps>
}

interface MenuItemProps {
    onClick: () => void
    children: React.ReactNode
    closeMenu?: () => void
}

export const DropDownMenu: React.FC<DropDownMenuProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)
    const menuRef = useRef<HTMLDivElement | null>(null)

    const toggleMenu = () => setIsOpen(!isOpen)
    const closeMenu = () => setIsOpen(false)

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                closeMenu()
            }
        }

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick)
        } else {
            document.removeEventListener('mousedown', handleOutsideClick)
        }

        return () => document.removeEventListener('mousedown', handleOutsideClick)
    }, [isOpen])

    return (
        <div ref={menuRef} className="dropdown">
            <div className="dropdown-trigger" onClick={toggleMenu}>
                <img className="settings-icon" src={icon} />
            </div>
            {isOpen && (
                <div className="dropdown-menu">
                    {React.Children.map(children, (child) =>
                        React.isValidElement(child) ? React.cloneElement(child, { closeMenu }) : child
                    )}
                </div>
            )}
        </div>
    )
}

export const MenuItem: React.FC<MenuItemProps> = ({ onClick, children, closeMenu }) => (
    <div
        className="dropdown-item"
        onClick={() => {
            onClick()
            closeMenu?.()
        }}
    >
        {children}
    </div>
)