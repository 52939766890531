import { makeAutoObservable } from "mobx"
import { makePersistable } from "mobx-persist-store"

interface PatientData {
  id: number | null
  name: string
  ssn: string
}

class PatientStore {
  patientData: PatientData = {
    id: null,
    name: "",
    ssn: ""
  }

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: "PatientStore",
      properties: ["patientData"], 
      storage: window.localStorage,
    })
  }

  setPatientData(id: number, name: string, ssn: string) {
    this.patientData = { id, name, ssn }
  }

  getPatientData() {
    return this.patientData
  }

  resetPatientData() {
    this.patientData = { id: null, name: "", ssn: "" }
  }
}

const patientStore = new PatientStore()
export default patientStore
