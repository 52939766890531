import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from 'root/store/context/store-context';

import './patient-search.less'

import icon from 'images/icons/warning.svg'

import { searchPatient } from 'root/helpers/api';

const PatientSearch = observer(() => {
    const [searchQuery, setSearchQuery] = useState('')
    const [patients, setPatients] = useState([{
        name: "",
        id: "",
        ssn: "",
        highlights: [],
        age: "",
        gender: "",
        hippoUser: true
    }])
    const [filteredPatients, setFilteredPatients] = useState([{
        name: "",
        id: "",
        ssn: "",
        highlights: [],
        age: "",
        gender: "",
        hippoUser: true
    }])
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { patientStore } = useStore();

    const hippoUserStyling: React.CSSProperties = {
        cursor: 'pointer'
    }

    const userStyling: React.CSSProperties = {
        backgroundColor: 'gray',
        cursor: 'not-allowed'
    }

    const selectPatient = (id: string) => {
        const selectedPatient = patients.find(patient => patient.id === id)
        if (!selectedPatient) return

        patientStore.setPatientData(
            selectedPatient.id,
            selectedPatient.name,
            selectedPatient.ssn
        )

        if (!selectedPatient.hippoUser) return

        navigate(`/dashboard/${id}`)
    }

    const handleSearchQueryChange = (query) => {
        setSearchQuery(query)

        if (query === '') {
            setFilteredPatients(patients);
        } else {
            const filtered = patients.filter((patient) =>
                patient.ssn.includes(query) || patient.name.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredPatients(filtered);
        }
    };

    useEffect(() => {
        const fetchPatient = async (searchQuery) => {
            const data = searchPatient(searchQuery).then((data) => {
                setPatients(data)
                setFilteredPatients(data)
            })
        }

        fetchPatient('')
    }, [])

    return (
        <div className="view-container search-view-container">
            <h2>{t('Patient search')}</h2>
            <input
                className="patient-search-input"
                type="text"
                placeholder={t('Search for a patient')}
                value={searchQuery}
                onChange={e => handleSearchQueryChange(e.target.value)}
                onKeyDown={e => e.key == "Enter" && handleSearchQueryChange(searchQuery)}
            />
            {/* 
            <button
                className="patient-search-button"
                onClick={() => fetchPatient(searchQuery)}
            >{t('Search')}</button>
            */}

            <h2 className="dashboard-header">{t('My patients')}</h2>
            <div className="grid-container">
                { // default empty array check, remove when fully integrated
                    filteredPatients.length != 0 && filteredPatients.map((patient, i) => patient.hippoUser === true &&
                        <div onClick={() => patient.hippoUser === true && selectPatient(patient.id)} className="patient-card" style={patient.hippoUser === true ? hippoUserStyling : userStyling} key={i}>
                            <div className="card-header">
                                <div className="patient-name">{patient.name}</div>
                                <div className="patient-ssn">{patient.ssn}</div>
                            </div>
                            <div className="card-content">
                                <div className="content-row">{t('Age')} {patient.age}</div>
                                <div className="content-row">{t('Gender')} {patient.gender}</div>
                            </div>
                            <div className="card-highlights">
                                <div className="card-warnings"><img className="icon" src={icon}/></div>
                                <div className="warnings-list">{patient.highlights.map((entry, i) => <div className="warning-entry" key={i}>{entry}</div>)}</div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
});

export default PatientSearch;