{
    "Dashboard": "Oma näkymä",
    "Patient search": "Potilashaku",
    "Sign out": "Kirjaudu ulos",
    "Summary": "Tiivistelmä",
    "Basic information": "Perustiedot",
    "Age": "Ikä",
    "Gender": "Sukupuoli",
    "Height": "Pituus",
    "Weight": "Paino",
    "Body mass index": "Painoindeksi",
    "Smokes": "Tupakoi",
    "Chronic diseases": "Pitkäaikaissairaudet",
    "Drug allergies": "Lääkeaineallergiat",
    "Blood pressure": "Verenpaine",
    "Medication list": "Lääkityslista",
    "Resting heart rate": "Leposyke",
    "Daily average steps": "Keskimääräiset askeleet päivässä",
    "Patient self-measurements": "Potilaan omat mittaukset",
    "Search": "Haku",
    "Search for a patient": "Hae potilasta",
    "My patients": "Omat potilaat",
    "Non-hippo users": "Eivät olet liittyneet systeemiin" ,
    "Date": "Päivämäärä",
    "Systolic": "Systolinen",
    "Diastolic": "Diastolinen",
    "Average": "Keskiarvo",
    "Steps": "Askeleet",
    "Lab results": "Laboratoriotulokset",
    "High": "Yläraja",
    "Low": "Alaraja",
    "Welcome to Hippo Clinic": "Tervetuloa Hippo Cliniciin",
    "Here you will find all relevant information regarding the use of Hippo Clinic": "Täältä löydät kaikki oleelliset tiedot liittyen Hippo Clinicin käyttöön",
    "Latest": "Ajankohtaista",
    "Versions & releases": "Versiot & julkaisut",
    "Contact us / Give us feedback": "Ota yhteyttä / Anna palautetta",
    "Resources": "Oppaat",
    "Adding a patient": "Potilaan lisäys",
    "Adding a patient body text": "Potilaita voidaan hakea potilashaun kautta henkilötunnuksen perusteella, mikäli he eivät ole vielä antaneet lukuoikeuksia tietoihinsa. Potilaan annettua lukuoikeudet omiin tietoihinsa, voidaan potilas lisätä \"Omat potilaat\"-listaan, jolloin tietoja voidaan tarkastella.",
    "Rights to read patient data": "Potilastietojen lukuoikeudet",
    "Rights to read patient data body text": "Hippon käyttäjillä on oma tietopankkinsa, jonka omistusoikeus on potilaalla. Potilas hallinnoi itsenäisesti kaikkia oikeuksia omiin tietoihinsa.",
    "Navigation between patients": "Navigaatio potilaiden välillä",
    "Navigation between patients body text": "Potilashaku mahdollistaa potilaiden etsimisen nimen tai henkilötunnuksen perusteella, mikäli heidän tietoihinsa on annettu pääsy. Hakutulosta klikkaamalla voidaan vaihtaa palvelutapahtumassa käsiteltävää potilasta.",
    "Modules": "Moduulit",
    "Modules body text": "Oma näkymä koostuu moduuleista, joita voidaan lisätä ja poistaa potilaan tarpeiden mukaan. Muokkaukset ovat potilaskohtaisia ja tallentuvat järjestelmään automaattisesti.",
    "Interpreting modules": "Moduulien tulkinta",
    "Interpreting modules body text": "Moduulit on jaettu aiheen ja käyttötapausten mukaan. Moduulien oikeasta alakulmasta voi nähdä moduulissa näytettävän datan alkuperän. Erityistä huomiota kaipaavat yksityiskohdat on merkitty huomiovärillä. Esimerkiksi viitearvot ylittävät laboratoriotulokset ovat oransseja.",
    "Adding and removing modules": "Moduulien lisäys ja poisto",
    "Using charts": "Kuvaajien käyttö",
    "Using charts body text": "Moduulit, jotka sisältävät kuvaajia esittävät potilaan tietoja visualisoituna. Hiiren vieminen kuvaajien päälle näyttää lisätietoja kuvaajien eri datapisteistä.",
    "Data added by patients": "Potilaan lisäämä data",
    "Data added by patients body text": "\"Potilaan omat mittaukset\"-otsikon alapuolella on moduuleja, joiden tietoja ei ole haettu OmaKannasta. Näitä moduuleja voivat olla esimerkiksi älylaitteista kerätyt tiedot.",
    "Save": "Tallenna",
    "Cancel": "Peruuta",
    "Edit dashboard layout": "Muokkaa näkymän järjestystä",
    "Expand module": "Laajenna moduuli",
    "Collapse module": "Pienennä moduuli"
}