import React from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import authStore from 'root/store/auth-store';
import { useTranslation } from 'react-i18next';
import { useStore } from 'root/store/context/store-context';

import image from 'images/hippo-logo.png';
import signOutIcon from 'images/icons/signout-icon.svg';
import './nav.less'


// TODO: language versioning
const Nav = observer(() => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { patientStore } = useStore();
    const { id: patientId } = patientStore.getPatientData()

    const handleSignOut = async () => {
        await authStore.signOut();
        navigate('/login', { replace: true });
    };
    
    const handleLanguageChange = (event) => {
        const language = event.target.value;
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    };

    if (authStore.isAuthenticated === null || authStore.isAuthenticated === false) {
        return null;
    }

    return (
        <div className="hippo-nav">

            <Link to={`/home`}><img className="res-logo" src={image} alt="Logo" /></Link>
            <div className="hippo-links">
                <div className="hippo-logo">Hippocr<span className="hippo-hl">AI</span>tes</div>
                {patientId !== null ? (
                    <Link to={`/dashboard/${patientId}`}>{t('Dashboard')}</Link>
                ) : (
                    <Link to="/dashboard">{t('Dashboard')}</Link>
                )}
                <Link to="/patient-search">{t('Patient search')}</Link>
            </div>
            {   /* Removed for now since functionality is to be determined // Alex 
            <a href="/enrich-patient-info">
                <div className="bt-add-view">+</div>
            </a>
                */
            }
            <div className="hippo-controls">
                <select
                    className="language-selector"
                    value={i18n.language}
                    onChange={handleLanguageChange}
                >
                    <option value="en">English</option>
                    <option value="fi">Suomi</option>
                </select>
                {authStore.isAuthenticated && (
                    <button className="signout-bt" onClick={handleSignOut}>
                        <img className="signout-icon" src={signOutIcon} alt="Sign out" /> {t('Sign out')}
                    </button>
                )}
            </div>
        </div>
    );
});

export default Nav;