import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStore } from 'root/store/context/store-context';
import './allergies.less'

import { getAllergies } from 'root/helpers/api'

import image from 'images/kanta_logo.png';
import icon from 'images/icons/hand-icon.svg'

interface Coding {
    system: string
    code: string
    display: string
}

interface Translation extends Coding {}

interface Code {
    coding: Coding[]
    text: string
    translation: Translation[]
}

interface Patient {
    reference: string
}

interface Manifestation {
    coding: Coding[]
    text: string
    translation: Translation[]
}

interface Reaction {
    manifestation: Manifestation[]
}

interface AllergyResource {
    resourceType: string
    id: string
    patient: Patient
    code: Code
    clinicalStatus: string
    reaction: Reaction[]
}

interface AllergyEntry {
    resource: AllergyResource
}

interface Allergies {
    resourceType: string
    type: string
    entry: AllergyEntry[]
}

const Allergies = observer(() => {
    const { t } = useTranslation()
    const { patientStore } = useStore()
    const { id: patientId } = patientStore.getPatientData()

    const [allergies, setAllergies] = useState<Allergies>()


    useEffect(() => {
        const fetchData = async () => {
            const id = patientId.toString()
            if (id) {
                const data = await getAllergies(id)
                return data
            }
            return null
        }

        if (patientId) {
            const result = fetchData().then((data) => setAllergies(data))
        }
    }, [patientId])

    const hasAllergy = (allergy) => {
        if (allergy == 'Aspiriiniallergia')
            return '#FCAE1E'
        return ''
    }


    return (
        <div className="hippo-module allergies-container">
            <h2>{t('Drug allergies')}</h2>
            <img className="module-icon" src={icon} />
            <div className="allergies-list">
                {allergies?.entry.map((allergy, i) =>
                    <div className="allergy-container hippo-list-item" style={{ color: hasAllergy(allergy.resource.code.translation[0].display) }} key={i}>
                        <div className="allergy-header" >{allergy.resource.code.translation[0].display}</div>
                        <div className="allergy-symptom">{allergy.resource.reaction.map((reaction) => reaction.manifestation.map((manifestation) => manifestation.translation[0].display))}</div>
                    </div>
                )}
            </div>
            <div className="hippo-source">
                <span className="source-txt"></span>
                <img className="source-logo" src={image} />
            </div>
        </div>
    )
})

export default Allergies